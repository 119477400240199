@import url('bootstrap/less/variables');
@import url('bootstrap/less/mixins');
@import url('bootstrap/less/normalize');
@import url('bootstrap/less/scaffolding');
@import url('bootstrap/less/type');
@import url('bootstrap/less/code');
@import url('bootstrap/less/grid');
@import url('bootstrap/less/tables');
@import url('bootstrap/less/forms');
@import url('bootstrap/less/buttons');
@import url('bootstrap/less/component-animations');
@import url('bootstrap/less/dropdowns');
@import url('bootstrap/less/button-groups');
@import url('bootstrap/less/input-groups');
@import url('bootstrap/less/navs');
@import url('bootstrap/less/breadcrumbs');
@import url('bootstrap/less/list-group');
@import url('bootstrap/less/wells');
@import url('bootstrap/less/close');
@import url('bootstrap/less/modals');
@import url('bootstrap/less/utilities');
@import url('bootstrap/less/responsive-utilities');

@import url('./variables');

@import url('./box');
@import url('./breadcrumb');
@import url('./buttons');
@import url('./dashboard-welcome-widget');
@import url('./dropdown-menu');
@import url('./form');
@import url('./full-screen-modal');
@import url('./graph');
@import url('./header');
@import url('./helpers');
@import url('./helpers-flexbox');
@import url('./icons');
@import url('./inline-edit-text-input');
@import url('./label');
@import url('./lineage');
@import url('./list-group');
@import url('./modal');
@import url('./floating-notifications');
@import url('./paginated-box');
@import url('./predefined-search');
@import url('./project-select-results');
@import url('./safari');
@import url('./select-group');
@import url('./separator');
@import url('./schedule-form');
@import url('./sidebar');
@import url('./react-table');
@import url('./tabs');
@import url('./table');
@import url('./tooltip');
@import url('./transitions');
@import url('./user');
@import url('./vault');
@import url('./well');

// imports 3rd party themes
// let's reuse KBC theme variables
@import url('./theme-kbc-codemirror');
@import url('./theme-kbc-chart');
@import url('./theme-kbc-jsoneditor');
@import url('./theme-kbc-markdown');
@import url('./theme-kbc-rc-switch');
@import url('./theme-kbc-react-select');
@import url('./theme-kbc-row-disabled');
@import url('./theme-kbc-sortable');
@import url('./theme-kbc-reactflow');

// sections
@import url('./blank-page');
@import url('./flow');
@import url('./info-row');
@import url('./global-search');
@import url('./gooddata-dashboard');
@import url('./sections/dashboard');
@import url('./sections/settings-page');
@import url('./sections/git-sync-page');
@import url('./sections/job-detail');
@import url('./sections/templates');
@import url('./sections/streams');
@import url('./sections/snowflake-partner-connect');
@import url('./components/dbt');
@import url('./components/animation-bar');
@import url('./components/backend-size-select');
@import url('./components/date-picker');
@import url('./components/dev-mode-bar');
@import url('./components/description-box');
@import url('./components/searchbar');
@import url('./components/slider-picker');
@import url('./components/billing');
@import url('./components/files-dropzone');
@import url('./components/data-sample-table');
@import url('./components/directory');
@import url('./components/overlay-opacity');
@import url('./components/destination-table-selector');
@import url('./components/select-timezone-dropdown');
@import url('./components/search-context-dropdown');
@import url('./components/split-diff');
@import url('./components/sort-icon');
@import url('./components/inline-edit-input');
@import url('./components/job-status-label');
@import url('./components/job-status-icon');
@import url('./components/jobs-advanced-search');
@import url('./components/modal-action-button');
@import url('./components/search-panel');
@import url('./components/support-form');
@import url('./components/simplified-form');
@import url('./components/tables-by-buckets');
@import url('./components/provisioning-credentials-modal');
@import url('./components/genericVariablesUI');
@import url('./components/genericCodeBlocksUI');
@import url('./components/genericNoCodeBlocksUI');
@import url('./components/dropdown-toggle-icon');
@import url('./components/demo-preview.less');
@import url('./utilities/spacing');
@import url('./params-table');
@import url('./params-list');

@import url('@keboola/design/styles');

body {
  background: @neutral-50;
  min-height: 100%;
  margin: 0;
  overflow-y: scroll;

  // Hide ProductFruits top bar when the automation or flow view is open
  &.tw-overflow-hidden:has(.tw-h-screen),
  &:has(.flow-panel) {
    padding-top: 0 !important;

    --product-fruits-top-bar-display: none;
  }
}

@media (max-width: @screen-sm-min) {
  .container {
    width: 100%;
  }
}

@media (min-width: @screen-xl) {
  .container {
    width: @container-xl;
  }
}

@media (min-width: @screen-xxl) {
  .container {
    width: @container-xxl;
  }
}

a {
  cursor: pointer;

  &:not(:where(.link-inherit, [role='menuitem'], [role='button'], [role='tab'])) {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &:hover,
  &:focus {
    text-decoration: none !important;
  }

  &.dark {
    color: @neutral-800;

    &.muted {
      color: @neutral-400;
    }

    &:hover,
    &:focus {
      color: @neutral-800;
    }
  }
}

h4 .fa-spinner {
  font-size: 11px;
}

hr {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  border-top: @border;

  &.wider {
    margin-right: -8px;
    margin-left: -12px;
  }
}

ul,
ol {
  padding-left: 20px;

  li > a:has(> .truncated:first-child) {
    display: inline-flex;
  }
}

textarea {
  resize: vertical;
}

mark {
  color: @secondary-500-base;
  background: @secondary-100;
  padding: 0;
  font-weight: 500;
}

.main-container:not(:has(> .blank-page)) {
  padding-bottom: 8em;
}

.help-block {
  font-size: 13px;

  &.text-danger {
    color: @error-500-base;
  }
}

.text-warning {
  color: @warning-500-base;
}

a,
button {
  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline: 4px dashed black;
  }
}
