@import (inline) 'codemirror/lib/codemirror.css';
@import (inline) 'codemirror/theme/solarized.css';
@import (inline) 'codemirror/addon/lint/lint.css';
@import (inline) 'codemirror/addon/hint/show-hint.css';

.CodeMirror-hints,
.CodeMirror-lint-tooltip {
  z-index: 1051;
}

.code-editor-preview {
  &:hover {
    .react-codemirror2 {
      .CodeMirror,
      .CodeMirror-gutters {
        background: @neutral-100;
      }
    }
  }
}

.react-codemirror2 {
  &.tw-max-h-none .CodeMirror .CodeMirror-scroll {
    max-height: none;
  }

  .cm-s-solarized {
    line-height: 20px;
  }

  .CodeMirror {
    height: auto;
    min-height: 40px;
    box-shadow: none;
    border-radius: @border-radius;
    background: @input-bg;
    font-family: @font-family-monospace;

    .CodeMirror-scroll {
      max-height: 100vh;
    }

    .CodeMirror-lines {
      padding: 10px 0;
    }

    .CodeMirror-placeholder {
      color: @input-color-placeholder;
    }
  }

  .CodeMirror-gutters {
    background: @input-bg;
  }

  .form-control-static .CodeMirror-cursor {
    display: none;
  }
}

.CodeMirror-lint-marker-error {
  left: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23e00025' d='M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z' /%3E%3C/svg%3E");
}

.CodeMirror-lint-tooltip {
  background-color: @white;
  border-color: @border-color;
  padding: 4px 8px;
  box-shadow: @box-shadow-secondary;

  .CodeMirror-lint-message {
    padding: 0;
    background: none;
  }
}

.CodeMirror-dialog {
  position: relative;
  z-index: 15;
  margin-left: auto;

  &:has(+ .btn-toolbar) {
    margin-right: 4px;
  }

  label {
    margin-bottom: 0;

    svg {
      position: absolute;
      top: 10px;
      left: 15px;
      pointer-events: none;
    }

    input {
      width: 320px;
      padding: 13px 150px 13px 44px;
    }
  }

  .CodeMirror-search-input-controls {
    position: absolute;
    top: 0;
    right: 6px;
    display: flex;

    .CodeMirror-search-count {
      margin: 11px 6px 0;
      color: @neutral-400;
      font-size: 12px;
      line-height: 18px;
    }

    .CodeMirror-search-divider {
      margin: 12px 6px 0;
    }

    & > button {
      margin-top: 4px;
      padding: 6px 6px 2px;

      &:hover svg path {
        fill: @secondary-500-base;
      }
    }
  }
}

span.cm-overlay.cm-searching {
  color: @secondary-500-base;
  background-color: @secondary-100;
}

.CodeMirror-selected,
.CodeMirror-focused .CodeMirror-selected {
  background: @secondary-500-base;
}

.CodeMirror-selectedtext {
  background: @secondary-500-base !important;
  color: @white !important;
}

.CodeMirror-matchingbracket {
  color: @secondary-500-base !important;
}

.CodeMirror-search-match {
  background: @secondary-500-base;
  border: 1px solid @secondary-100;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  opacity: 0.5;
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-break: break-all;
}
