@cmdk-gray: rgba(255, 255, 255, 0.7);

[cmdk-dialog] {
  position: fixed;
  z-index: 1051;
  overflow: hidden;
  outline: 0;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);

  [cmdk-root] {
    width: 100%;
    max-width: 800px;
    margin: 75px auto 30px;
    background: @white;
    border-radius: @border-radius;
    overflow: hidden;
    box-shadow: 0 10px 20px #6487992e;
  }

  [cmdk-input] {
    border: none;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 200px 20px 60px;
    outline: none;
    background: @neutral-800;
    color: @white;

    &::placeholder {
      color: @cmdk-gray;
    }
  }

  [cmdk-group] {
    position: relative;
    padding-top: 10px;
  }

  [cmdk-group-heading] {
    color: @neutral-800;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 24px;
  }

  [cmdk-item] {
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin: 0 12px;
    border-radius: @border-radius;
    color: @neutral-800;
    white-space: pre-wrap;

    &[aria-selected='true'] {
      background-color: @neutral-100;
    }

    .type {
      margin-left: 4px;
    }
  }

  [cmdk-list] {
    max-height: 480px;
    height: var(--cmdk-list-height);
    scroll-padding-block-start: 10px;
    scroll-padding-block-end: 10px;
    overflow-y: auto;
  }

  [cmdk-list-sizer] {
    padding: 5px 0 15px 0;

    & > [cmdk-item]:first-child {
      margin-top: 10px;
    }
  }

  [cmdk-empty] {
    font-size: 14px;
    line-height: 20px;
    color: @neutral-400;
    text-align: center;
    padding: 30px 0 20px 0;
  }

  .input-with-addons {
    position: relative;

    .fa-magnifying-glass {
      position: absolute;
      top: 24px;
      left: 24px;
      color: @cmdk-gray;
    }

    button {
      position: absolute;
      top: 20px;
      height: 24px;
      right: 156px;
      color: @cmdk-gray;
      border-right: 1px solid @neutral-500-base;
      border-radius: 0;
      padding: 0px 8px;
      margin-right: 8px;

      &:hover,
      &:focus {
        color: @white;
        outline: none;
        border-color: @neutral-500-base;
      }

      svg {
        bottom: 0;
      }
    }

    .addon {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 24px;
      padding: 20px 24px 20px 0;
      color: @cmdk-gray;

      span {
        color: @neutral-600;
        background: @white;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        padding: 2px 4px;
        margin: 0 8px;
        border-radius: 2px;
        letter-spacing: @letter-spacing-wider;
      }
    }
  }
}

.project-filters {
  width: 320px;
  border-left: 1px solid @neutral-150;
  padding: 24px;

  .common-checkbox {
    align-items: flex-start;

    label {
      margin-top: -1px;
    }
  }
}

.gs-root {
  position: fixed;
  z-index: 1051;
  overflow: hidden;
  outline: 0;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .input-with-addons {
    position: relative;

    .fa-magnifying-glass {
      position: absolute;
      top: 24px;
      left: 24px;
      color: @neutral-400;
    }

    button:last-child {
      color: @neutral-400;
      border-left: 1px solid @neutral-200;
      border-radius: 0;
      padding: 0 0 0 12px;
      margin-left: 12px;

      &:hover,
      &:focus {
        color: @neutral-500-base;
        outline: none;
      }

      svg {
        bottom: 0;
      }
    }

    .addon {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      line-height: 24px;
      padding-right: 24px;
      height: 100%;
      color: @neutral-400;
    }
  }
}

.gs-dialog {
  max-width: 1080px;
  background: @white;
  border-radius: @border-radius;
  overflow: hidden;
  box-shadow: 0 10px 20px #6487992e;
  margin: 75px auto 30px;

  @media (max-width: @screen-md-min) {
    margin-left: 15px;
    margin-right: 15px;
  }

  .radio input[type='radio']:not(:checked) {
    opacity: 0.55;
  }
}

.gs-group-title {
  color: @neutral-800;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 8px 24px;
  margin: 0;
}

.gs-item {
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  display: flex !important;
  align-items: center;
  padding: 0 12px;
  margin: 0 12px;
  border-radius: @border-radius;
  color: @neutral-800;
  white-space: pre-wrap;

  &.is-active {
    background-color: @neutral-100;
  }

  &:not(.tw-cursor-not-allowed) {
    &:hover {
      background-color: @neutral-100;
    }
  }

  &:has(.gs-type > :empty) {
    .gs-item-separator {
      display: none;
    }
  }
}

.gs-item + .gs-group-title {
  padding-top: 20px;
}

.gs-list {
  max-height: 480px;
  height: 700px;
  scroll-padding-block-start: 10px;
  scroll-padding-block-end: 10px;
  overflow-y: auto;
}

.gs-list-inner {
  padding: 12px 0 12px 0;

  & > .gs-item:first-child {
    margin-top: 10px;
  }
}

.gs-input {
  border: none;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 400px 20px 60px;
  outline: none;
  background: @neutral-50;
  color: @neutral-400;

  &::placeholder {
    color: @neutral-400;
  }
}

.gs-trigger {
  all: unset;

  .gs-trigger-input {
    display: none;
    @media (min-width: @screen-lg-min) {
      display: flex;
    }
  }

  .fa-magnifying-glass {
    color: @neutral-400;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 18px;
    top: 12px;

    @media not all and (min-width: @screen-lg-min) {
      position: relative;
      top: 2px;
      left: 0;
      width: 22px;
      height: 22px;

      &:hover {
        cursor: pointer;
        color: @secondary-500-base;
      }
    }
  }

  .addon {
    position: absolute;
    display: none;
    right: 16px;
    top: 10px;
    width: 16px;
    height: 20px;
    border: 1px solid @neutral-300;
    border-radius: 2px;
    color: @neutral-400;
    text-align: center;
    font-size: 11px;
    line-height: 16px;

    @media (min-width: @screen-lg-min) {
      display: block;
    }
  }
}
