.backend-size-select {
  .react-select__menu {
    margin: 0 !important;
    box-shadow: @box-shadow-secondary;
  }

  .react-select__option--is-selected {
    font-weight: 500;
  }

  .react-select__option {
    .fa-rocket {
      color: @neutral-400;
    }

    &:hover .fa-rocket {
      color: @secondary-500-base;
    }

    &:is(.react-select__option--is-focused, .react-select__option--is-selected):not(:hover) {
      background-color: initial !important;
    }
  }
}

.backend-size-buttons {
  span {
    &:has(~ :where(:hover, :focus, .active)) {
      svg {
        color: @secondary-500-base;
      }
    }

    button {
      border: inherit;
      padding: inherit;
      color: @secondary-200;
      margin-left: 0px !important;

      &:where(:hover, :focus, .active) {
        color: @secondary-500-base;
      }

      &:hover ~ button {
        color: @secondary-200 !important;
      }
    }
  }
}
