.form-control {
  box-shadow: none;

  &.without-decorations {
    border: none !important;
    box-shadow: none !important;
  }
}

.form-control[type='date'],
.form-control[type='datetime-local'] {
  padding: 0px 16px;
}

label,
.control-label {
  font-weight: 400;
}

.input-group-addon {
  background: @neutral-150;
}

.form-group.has-error,
.has-error:has(> .form-control) {
  & .control-label {
    color: inherit;
  }

  .form-control:not([disabled]) {
    border: 1px solid @transparent;
    box-shadow: none;
    color: @error-500-base;
    background-color: @error-100;

    &:focus {
      border: 1px solid @transparent;
      box-shadow: none;
    }
  }

  .input-group-addon {
    color: @neutral-800;
    background: @neutral-150;
    border-color: @transparent;
  }
}

textarea.form-control {
  line-height: 20px;
}

select.form-control {
  padding: 0px 16px;
}

.Select + .help-block,
.form-control:not(.inline-edit-input-control) + .help-block {
  margin-left: 1px;
  margin-right: 1px;
}

.form-control:not(.inline-edit-input-control):not(.without-decorations):focus,
.Select .react-select__control--is-focused {
  border: 1px solid @secondary-500-base !important;
  box-shadow: @outline-shadow;
  border-radius: @border-radius;
}

.form-control[disabled],
.Select.react-select--is-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.radio {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid @transparent;

  label {
    padding-left: 22px;
  }

  &.disabled {
    opacity: 0.4;
  }

  input[type='radio'] {
    width: 17px;
    height: 17px;
    margin-top: 2px;
    margin-left: -22px;
  }
}

.above-table-with-buttons .common-checkbox {
  margin-right: 10px;
  margin-left: 4px;
}

@media all and (min-width: @screen-sm-min) {
  .form-horizontal .control-label {
    padding-top: 10px;
    text-align: left;
  }
}

.form-control-static {
  .overflow-break-anywhere();

  &.form-control {
    background-color: @neutral-150;
  }

  .switch-state-link {
    cursor: pointer;
    color: @neutral-800;
    text-decoration: none;
    button {
      margin-right: 0.5em;
    }
  }
}

.input-error .inline-edit-input .form-control,
.inline-edit-input.invalid .form-control {
  border-bottom-color: @error-500-base !important;
}

.input-with-addon {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .addon {
    position: absolute;
    bottom: 8px;
    right: 13px;
  }
}

.inputs-group {
  margin: 8px 0 18px;
  padding: 16px;
  border: @border;
  border-radius: @border-radius;
}

.common-checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;

  &.disabled {
    opacity: 0.4;
  }

  &:not(.disabled) * {
    cursor: pointer;
  }

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    opacity: 0;
    margin: 0;

    &:disabled {
      pointer-events: none;
    }
  }

  input[type='checkbox']:checked + .checkbox-ui {
    background-color: @secondary-500-base;
    border-color: @secondary-500-base;

    .checkbox-icon {
      display: block;
    }
  }

  input[type='checkbox']:indeterminate + .checkbox-ui {
    svg {
      display: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 8px;
      border-radius: 2px;
      background-color: @neutral-300;
    }
  }

  input[type='checkbox']:checked:focus-visible + .checkbox-ui,
  input[type='checkbox']:not(:checked):focus-visible + .checkbox-ui {
    border-color: @secondary-500-base;
  }

  .checkbox-ui {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
    color: #fff;
    border: 1px solid @neutral-200;
    border-radius: 3px;
    pointer-events: none;
  }

  label {
    margin: 0;
    padding-left: 8px;
  }
}

* + .common-checkbox:has(label),
.common-checkbox.block {
  display: flex;

  label {
    flex: 1;
  }
}

.radio.radio-btn {
  margin: 0;

  label {
    width: 100%;
    padding: 16px 16px 16px 38px;
    border-radius: 8px;
    border: 1px solid @neutral-200;
  }

  input {
    margin-left: -24px;
  }

  &:hover label,
  &:focus-within label {
    border-color: @secondary-500-base;
    outline: 1px solid @secondary-500-base;
  }
}
